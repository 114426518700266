import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IProfileLinks } from '../../../models/profilelink.model';
import { IProduct } from './header.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HeaderService {

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    getAllUserProfileLinks(apiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${apiUrl}/Products/getAllUserProfileLinks`).pipe(
            catchError(this.handleError)
        );
    }

    getEntitledUserProfileLinks(userId: string, token: string, apiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${apiUrl}/Products/getEntitledUserProfileLinks?token=${token}&userId=${userId}`).pipe(
            catchError(this.handleError)
        );
    }

    getUserProducts(userId: string, token: string, apiUrl: string): Observable<IProduct[]> {
        const appId = this.cookieService.get('prd_ctx') ? this.cookieService.get('prd_ctx') : window.location.hostname;

        return this.http.get<IProduct[]>(`${apiUrl}/Products/getUserProducts?userId=${userId}&token=${token}&appId=${appId}`).pipe(
            catchError(this.handleError)
        );
    }

    getAllProducts(apiUrl: string) {
        return this.http.get(`${apiUrl}/Products/getAllProducts`).pipe(
            catchError(this.handleError)
        );
    }

    public handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
