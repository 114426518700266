import { Component, OnInit } from '@angular/core';
import { LOBModel } from '../../../models/profile.experience.model';
import { AdobeAnalyticsService } from '../../shared/service/adobe-analytics.service';
import { FileService } from '../../shared/service/experience.profile.service';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from '../../app.service';
import { IConfig } from 'src/models/config';
import { home_resources } from '../../shared/resource/resource';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public documents: LOBModel[] = [];
  isUserEligible = false;
  showNotAuthorized = false;
  appConfig: IConfig;
  download = home_resources.en.download;
  deepLinkURL: string = '';

  constructor(private adobeAnalyticsService: AdobeAnalyticsService,
    private _lobDataService: FileService, private appService: AppService,
    private cookieService: CookieService) {
    this.appService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          this.appConfig = this.appService.getGlobalConfig();

          this._lobDataService.getFilesList(this.appConfig.apiUrl).subscribe((data: LOBModel[]) => {
            if (data) {
              this.documents = data;
              this.isUserEligible = data.length > 0;
              this.showNotAuthorized = data.length === 0;
            }
          });
        }
      });

    this.adobeAnalyticsService.emit(
      {
        page: {
          pageInfo: {
            pageID: '1'
          },
          category: {
            primaryCategory: 'home',
            subCategory1: ''
          }

        }
      },
      "end"
    );
  }

  ngOnInit(): void {
    this.deepLinkURL = window.location.href;
  }
}
