import { IConfig } from './../../../models/config';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
  appConfig: IConfig;
  customerSupportUrl: string;
  productName = 'ISO Experience Profiler';
  deepLinkURL: string = '';
  constructor(private appService: AppService) {
  }

  ngOnInit() {
    this.deepLinkURL = window.location.href;

    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();
        if(this.appConfig && this.appConfig.veriskCustomerSupport){
          this.customerSupportUrl = this.appConfig.veriskCustomerSupport;
        }
      }
    });
  }

}
